import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupText, InputGroup, Row, Col } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import { firebaseAuth } from "../../firebase/config.js";
import { sendPasswordResetEmail } from "firebase/auth";
import { useState, useCallback } from "react";
import AlertPopup from "./AlertPopup.js";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [alertType, setAlertType] = useState("danger");
  const [alertMessage, setAlertMessage] = useState("");
  const [email, setEmail] = useState("");

  const isValidEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (isValidEmail(email)) {
        sendPasswordResetEmail(firebaseAuth, email)
          .then(() => {
            navigate("/auth/login");
          })
          .catch((err) => {
            setAlertType("danger");
            setAlertMessage(`Could not reset password: ${err.message}`);
            setVisible(true);
          });
      } else {
        setAlertType("danger");
        setAlertMessage(`Please enter a valid email`);
        setVisible(true);
      }
    },
    [email, navigate]
  );

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  return (
    <>
      <AlertPopup showAlert={visible} alertType={alertType} onAlertClick={() => setVisible(false)} message={alertMessage} />
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Reset your password</small>
            </div>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                  <Input placeholder="Email" type="email" autoComplete="new-email" name="email" value={email} onChange={handleEmailChange} />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button className="mt-4" color="primary" type="submit">
                  Send reset email
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link className="text-light" to="/auth/login">
              <small>Back to login</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ForgotPassword;
