import { onSnapshot } from "firebase/firestore";
import { useState, useEffect } from "react";
import { gamesCollection } from "../firebase/config.js";

export const useFetchGames = () => {
  const [games, setGames] = useState([]);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    setIsPending(true);
    const unsub = onSnapshot(gamesCollection, (snapshot) => {
      const newGames = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      setGames(newGames);
      setIsPending(false);
    });

    return () => unsub();
  }, []);

  return { games, isPending };
};
