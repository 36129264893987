import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { Navbar, Nav, Container } from "reactstrap";
import UserNavPopup from "../../views/examples/UserNavPopup";

const DashboardNavbar = () => {
  const { user } = useContext(UserContext);

  return (
    <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid className="justify-content-end">
        <Nav className="align-items-center d-none d-md-flex" navbar>
          <UserNavPopup user={user} />
        </Nav>
      </Container>
    </Navbar>
  );
};

export default DashboardNavbar;
