const NoGamesRow = (props) => {
  return (
    <tr>
      <th scope="row">
        <span>{props.rowText}</span>
      </th>
    </tr>
  );
};

export default NoGamesRow;
