import { loadStripe } from "@stripe/stripe-js";
const isDevelopment = process.env.NODE_ENV === "development";
const betMagicStripeBackendUrl = isDevelopment ? "http://localhost:8000" : "https://bmlpayments.com";
const stripeKey = isDevelopment ? process.env.REACT_APP_STRIPE_DEV_PUBLISHABLE_KEY : process.env.REACT_APP_STRIPE_PROD_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripeKey);

const redirectUserToCheckout = (email) => {
  fetch(`${betMagicStripeBackendUrl}/api/v1/create-checkout-session`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      window.location.href = data.url;
    });
};

const redirectUserToAccountManagement = (stripeId) => {
  fetch(`${betMagicStripeBackendUrl}/api/v1/create-customer-portal-session`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ stripeId: stripeId }),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      window.location.href = data.url;
    });
};

export { stripePromise, betMagicStripeBackendUrl, redirectUserToCheckout, redirectUserToAccountManagement };
