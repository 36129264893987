import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "../../firebase/config";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Media } from "reactstrap";
import profileIcon from "../../assets/img/theme/profile-icon.jpg";

const UserNavPopup = ({ user }) => {
  const navigate = useNavigate();
  const discordInviteLink =
    process.env.NODE_ENV === "development" ? process.env.REACT_APP_DISCORD_QA_INVITE_LINK : process.env.REACT_APP_DISCORD_PROD_INVITE_LINK;

  const signUserOut = async () => {
    try {
      await signOut(firebaseAuth);
      navigate("/auth/login");
    } catch (err) {
      console.error("Sign out error", err);
    }
  };

  return (
    <UncontrolledDropdown nav>
      <DropdownToggle nav className="pr-0">
        <Media className="align-items-center">
          <span className="avatar avatar-sm rounded-circle">
            <img alt="Profile icon" src={profileIcon} />
          </span>
          {user && (
            <Media className="ml-2 d-none d-lg-block">
              <span className="mb-0 text-sm font-weight-bold">{user.email || "No user"}</span>
            </Media>
          )}
        </Media>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow" end>
        <DropdownItem header tag="div">
          <h6 className="text-overflow m-0">Welcome!</h6>
        </DropdownItem>
        <DropdownItem to="/dashboard/user-profile" tag={Link}>
          <i className="ni ni-single-02" />
          <span>Account</span>
        </DropdownItem>
        <DropdownItem href={discordInviteLink} target="_blank">
          <i className="fa-brands fa-discord" />
          <span>Discord</span>
        </DropdownItem>
        <DropdownItem href="https://betmagic.app/help/" target="_blank">
          <i className="ni ni-support-16" />
          <span>Support</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          href="#"
          onClick={(e) => {
            e.preventDefault();
            signUserOut();
          }}
        >
          <i className="ni ni-user-run" />
          <span>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserNavPopup;
