import React from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { formatDate, formatTime, getCellStyle, getGameTotal, getStatusBadgeColor } from "../../utils";

const GameCard = ({ game, sportsIconClass, viewGame }) => {
  return (
    <Card className="card-stats mb-4 mb-xl-0 shadow" onClick={viewGame}>
      <CardBody>
        <Row>
          <Col>
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              {game.home_team} vs {game.away_team}
            </CardTitle>
            <Row className="mt-3">
              <Col>
                <span className="h2 font-weight-bold mb-0">Predicted Total: {getGameTotal(game, "draftkings")}</span>
                <br />
                <span className="h2 font-weight-bold mb-0">Live Total: {game.liveTotal}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <span className="h2 font-weight-bold mb-0">
              Over: <span className={getCellStyle(game.liveTotal, game.over, false)}>{game.over}</span>
            </span>
            <br />
            <span className="h3 font-weight-bold mb-0">
              Under: <span className={getCellStyle(game.liveTotal, game.under, true)}>{game.under}</span>
            </span>
          </Col>
          <Col className="col-auto">
            <div className={`icon icon-shape text-white rounded-circle shadow ${getStatusBadgeColor(game.status)}`}>
              <i className={sportsIconClass} />
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <span className="h3 font-weight-bold mb-0">Date: {formatDate(game.commence_time)}</span>
            <br />
            <span className="h3 font-weight-bold mb-0">Time: {formatTime(game.commence_time)}</span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default GameCard;
