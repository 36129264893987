import React from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const StatsCard = ({ title, mainText, secondaryText, iconClass, showStats, statsType, percentage }) => (
  <Card className="card-stats mb-4 mb-xl-0">
    <CardBody>
      <Row>
        <div className="col">
          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
            {title}
          </CardTitle>
          <span className="h2 font-weight-bold mb-0">{mainText}</span>
          {secondaryText && <br />}
          {secondaryText && <span className="h2 font-weight-bold mb-0">{secondaryText}</span>}
        </div>
        <Col className="col-auto">
          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
            <i className={iconClass} />
          </div>
        </Col>
      </Row>
      {showStats && <StatsIndicator type={statsType} percentage={percentage} />}
    </CardBody>
  </Card>
);

const StatsIndicator = ({ type, percentage }) => (
  <p className="mt-3 mb-0 text-muted text-sm">
    <span className={type === "over" ? "text-success mr-2" : type === "under" ? "text-danger mr-2" : "text-dark mr-2"}>
      <i className={type === "over" ? "fa fa-arrow-up" : type === "under" ? "fa fa-arrow-down" : "fa fa-arrow-right"} /> {percentage.toFixed(2)}%
    </span>
    <span className="text-nowrap">{type}</span>
  </p>
);

const Header = ({
  showHeaders,
  league,
  currentGames,
  total = "N/A",
  headerIconClass = "fa-solid fa-chart-line",
  showStats,
  type,
  percentage,
  showSpreadHeader,
  spread,
}) => {
  return (
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
        <div className="header-body">
          <Row>
            <Col lg="6" xl="3">
              {showHeaders && (
                <StatsCard
                  title={league !== undefined ? `Current ${league} games` : "Original Total"}
                  mainText={currentGames !== undefined ? currentGames : total}
                  iconClass={headerIconClass}
                  showStats={showStats}
                  statsType={type}
                  percentage={percentage}
                />
              )}
            </Col>
            <Col lg="6" xl="4">
              {showSpreadHeader && (
                <StatsCard
                  title="Original Spread"
                  mainText={`${spread?.[0]?.name || "N/A"}: ${spread?.[0]?.point || "N/A"}`}
                  secondaryText={`${spread?.[1]?.name || "N/A"}: ${spread?.[1]?.point || "N/A"}`}
                  iconClass={headerIconClass}
                />
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Header;
