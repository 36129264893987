import React from "react";
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const navLinks = [
  { href: "https://betmagic.app", text: "Learn More" },
  { href: "https://betmagic.app/#", text: "About Us" },
  { href: "https://betmagic.app/help/", text: "Support" },
  { href: "https://betmagic.app/privacy-policy/", text: "Terms & Privacy" },
];

const NavItemComponent = ({ href, text }) => (
  <NavItem>
    <NavLink href={href} target="_blank">
      {text}
    </NavLink>
  </NavItem>
);

const Login = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="py-5">
      <Container>
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">© {currentYear}</div>
          </Col>
          <Col xl="6">
            <Nav className="nav-footer justify-content-center justify-content-xl-end">
              {navLinks.map((link, index) => (
                <NavItemComponent key={index} href={link.href} text={link.text} />
              ))}
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Login;
