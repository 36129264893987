import React from "react";
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";

const navLinks = [
  { href: "https://betmagic.app", text: "Home" },
  { href: "https://betmagic.app/", text: "About Us" },
  { href: "https://betmagic.app/help/", text: "Help" },
  { href: "https://betmagic.app/privacy-policy/", text: "Terms & Privacy" },
];

const NavItemComponent = ({ href, text }) => (
  <NavItem>
    <NavLink href={href} rel="noopener noreferrer" target="_blank">
      {text}
    </NavLink>
  </NavItem>
);

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            © {currentYear}{" "}
            <a className="font-weight-bold ml-1" href="https://betmagic.app" rel="noopener noreferrer" target="_blank">
              Bet Magic
            </a>
          </div>
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
            {navLinks.map((link, index) => (
              <NavItemComponent key={index} href={link.href} text={link.text} />
            ))}
          </Nav>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
