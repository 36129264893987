import { Badge, Media } from "reactstrap";
import { formatDate, formatTime, getCellStyle, getGameTotal, getStatusBadgeColor } from "../../utils";

const GameRow = ({ game, viewGame }) => {
  const rowName = `${game.home_team} vs ${game.away_team}`;
  const iconUrl = require(`../../assets/img/theme/${game.sport_key}.jpg`);

  return (
    <tr onClick={viewGame}>
      <th scope="row">
        <Media className="align-items-center">
          <img className="avatar rounded-circle mr-3" alt="..." src={iconUrl} />
          <Media>
            <span className="mb-0 text-sm">{rowName}</span>
          </Media>
        </Media>
      </th>
      <td>{getGameTotal(game, "draftkings")}</td>
      <td>{game.liveTotal}</td>
      <td>
        <span className={getCellStyle(game.liveTotal, game.over, false)}>{game.over}</span>
      </td>
      <td>
        <span className={getCellStyle(game.liveTotal, game.under, true)}>{game.under}</span>
      </td>
      <td>{formatDate(game.commence_time)}</td>
      <td>{formatTime(game.commence_time)}</td>
      <td>
        <Badge color="" className="badge-dot mr-4">
          <i className={getStatusBadgeColor(game.status)} />
          {game.status}
        </Badge>
      </td>
    </tr>
  );
};

export default GameRow;
