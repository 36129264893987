import { Alert } from "reactstrap";
const AlertPopup = (props) => {
  return (
    <Alert
      isOpen={props.showAlert}
      color={props.alertType}
      onClick={props.onAlertClick}
      style={{
        zIndex: 1,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
      }}
    >
      {props.message}
    </Alert>
  );
};
export default AlertPopup;
