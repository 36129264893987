import { useEffect, useState } from "react";
import { query, onSnapshot, orderBy, where, doc, getDoc } from "firebase/firestore";
import { totalsCollection, firebaseDb } from "../firebase/config.js";
import differenceInMinutes from "date-fns/differenceInMinutes";
import parseISO from "date-fns/parseISO";
import { getLineValue } from "../utils.js";

export const useFetchGameTotals = (gameId) => {
  const [startTime, setStartTime] = useState("");
  const [percentage, setPercentage] = useState(0.0);
  const [type, setType] = useState("");
  const [total, setTotal] = useState(0);
  const [totalData, setTotalData] = useState({
    labels: ["00:00"],
    datasets: [
      {
        label: "Live Total",
        data: ["0"],
      },
      {
        label: "Predicted Total",
        data: ["0"],
        borderColor: "#fb6340",
        backgroundColor: "#fb6340",
      },
      {
        label: "Bet Under",
        data: ["0"],
        borderColor: "#40fb63",
        backgroundColor: "#40fb63",
      },

      {
        label: "Bet Over",
        data: ["0"],
        borderColor: "#40fb63",
        backgroundColor: "#40fb63",
      },
    ],
  });

  useEffect(() => {
    if (!gameId) return;

    const fetchGameTotals = async () => {
      const gameDocRef = doc(firebaseDb, "games", gameId);
      const game = await getDoc(gameDocRef);
      let sport_key = game.data().sport_key;

      let selectedBookmaker = game.data().bookmakers.find((b) => b.key === "draftkings");
      let totalsMarket = selectedBookmaker.markets.find((m) => m.key === "totals");

      setStartTime(game.data().commence_time);
      setTotal(totalsMarket.outcomes[0].point);

      const q = query(totalsCollection, where("gameId", "==", gameDocRef), orderBy("createdAt", "asc"));

      const unsub = onSnapshot(q, (snapshot) => {
        const liveTotals = snapshot.docs.map((doc) => doc.data().outcomes[0].point);
        const timestampLabels = snapshot.docs.map((doc) => {
          const time = Math.max(0, differenceInMinutes(parseISO(doc.data().createdAt), parseISO(startTime)));
          const hour = Math.floor(time / 60);
          const minute = time % 60;
          return `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
        });

        setPercentage(snapshot.docs[snapshot.docs.length - 1]?.data().percentage || 0.0);
        setType(snapshot.docs[snapshot.docs.length - 1]?.data().type || "equal");
        setTotalData({
          labels: timestampLabels,
          datasets: [
            {
              label: "Live Total",
              data: liveTotals,
            },
            {
              label: "Predicted Total",
              data: new Array(timestampLabels.length).fill(totalsMarket.outcomes[0].point),
              borderColor: "#fb6340",
              backgroundColor: "#fb6340",
            },
            {
              label: "Bet Under",
              data: new Array(timestampLabels.length).fill(getLineValue(sport_key, totalsMarket.outcomes[0].point, true)),
              borderColor: "#40fb63",
              backgroundColor: "#40fb63",
            },

            {
              label: "Bet Over",
              data: new Array(timestampLabels.length).fill(getLineValue(sport_key, totalsMarket.outcomes[0].point, false)),
              borderColor: "#40fb63",
              backgroundColor: "#40fb63",
            },
          ],
        });
      });

      return () => unsub();
    };

    fetchGameTotals();
  }, [gameId, startTime]);

  return { total, totalData, percentage, type };
};
