import GameRow from "./GameRow.js";
import NoGamesRow from "./NoGamesRow.js";
import { Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import GameCard from "./GameCard.js";

const GamesTable = ({ tableClassName, theadClassName, games, isCurrentGames, noGameText, sportsIconClass }) => {
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  const navigateToGame = (game) => {
    if (isCurrentGames) {
      let selectedBookmaker = game.bookmakers.find((b) => b.key === "draftkings");
      let totalsMarket = selectedBookmaker.markets.find((m) => m.key === "totals");
      navigate(game.id, {
        state: {
          homeTeam: game.home_team,
          awayTeam: game.away_team,
          homeTeamScore: game.homeTeamScore,
          awayTeamScore: game.awayTeamScore,
          total: totalsMarket.outcomes[0].point,
          sport: game.sport_key,
        },
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isMobileView) {
    return (
      <div>
        {games.length > 0 ? (
          games.map((game) => <GameCard key={game.id} game={game} sportsIconClass={sportsIconClass} viewGame={() => navigateToGame(game)} />)
        ) : (
          <Table className={`align-items-center ${tableClassName}`} responsive>
            <tbody>
              <NoGamesRow rowText={noGameText} />
            </tbody>
          </Table>
        )}
      </div>
    );
  } else {
    return (
      <Table className={`align-items-center ${tableClassName}`} responsive>
        <thead className={theadClassName}>
          <tr>
            <th scope="col">Games</th>
            <th scope="col">Predicted Total</th>
            <th scope="col">Live Total</th>
            <th scope="col">Over</th>
            <th scope="col">Under</th>
            <th scope="col">Start Date</th>
            <th scope="col">Start Time</th>
            <th scope="col">Game Status</th>
          </tr>
        </thead>
        <tbody>
          {games.length > 0 ? (
            games.map((game) => <GameRow key={game.id} game={game} viewGame={() => navigateToGame(game)} />)
          ) : (
            <NoGamesRow rowText={noGameText} />
          )}
        </tbody>
      </Table>
    );
  }
};

export default GamesTable;
