import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCXMRBK5HSafR_GKi0vgtLl2kQQevIUO8g",
  authDomain: "betting-magic.firebaseapp.com",
  projectId: "betting-magic",
  storageBucket: "betting-magic.appspot.com",
  messagingSenderId: "995567394873",
  appId: "1:995567394873:web:a5d72ef8d633659d4c2de8",
  measurementId: "G-7FKP2013E1",
};

const app = initializeApp(firebaseConfig);

//Get a debug token for your local machine (register it in the appcheck section of Firebase).
if (process.env.NODE_ENV === "development") {
  window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

// eslint-disable-next-line
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfK_38iAAAAAH7eaBwcyJY2R3hr6ZhVeFgiqNEX"),
  isTokenAutoRefreshEnabled: true,
});

const firebaseDb = getFirestore(app);
const firebaseAuth = getAuth(app);
const usersCollection = collection(firebaseDb, "users");
const gamesCollection = collection(firebaseDb, "games");
const totalsCollection = collection(firebaseDb, "totals");
const spreadCollection = collection(firebaseDb, "spreads");

const firebaseAnalytics = getAnalytics(app);

export { firebaseDb, firebaseAuth, usersCollection, gamesCollection, totalsCollection, spreadCollection, firebaseAnalytics };
