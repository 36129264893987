import { useState, useRef } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import "chart.js/auto";
import { Line } from "react-chartjs-2";
import { Card, CardHeader, CardBody, NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

import { options } from "../variables/charts.js";

import Header from "../components/Headers/Header.js";
import { useLocation, useParams } from "react-router-dom";

import { useFetchGameTotals } from "../hooks/useFetchGameTotals.js";
import { useFetchGameSpreads } from "../hooks/useFetchGameSpreads.js";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);

  const { state } = useLocation();

  const { gameId } = useParams();
  const { total, totalData, percentage, type } = useFetchGameTotals(gameId);
  const { spread, spreadData } = useFetchGameSpreads(gameId);

  const totalsChart = useRef();
  const spreadsChart = useRef();

  const toggleNavs = (index) => {
    setActiveNav(index);
  };

  const handleTouch = (enabled) => {
    const totalChartInstance = totalsChart.current;
    const spreadChartInstance = spreadsChart.current;
    if (totalChartInstance) {
      // Temporarily disable tooltips
      totalChartInstance.options.plugins.tooltip.enabled = enabled;
      totalChartInstance.update();
    }
    if (spreadChartInstance) {
      // Temporarily disable tooltips
      spreadChartInstance.options.plugins.tooltip.enabled = enabled;
      spreadChartInstance.update();
    }
  };

  return (
    <>
      <Header showHeaders={true} showStats={true} total={total} percentage={percentage} type={type} showSpreadHeader={true} spread={spread} />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0">
            <Card className="bg-gradient-white shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-primary ls-1 mb-1">Current Game</h6>
                    <h2 className="text-dark mb-0">{state !== null ? `${state?.homeTeam} vs ${state?.awayTeam}` : "Coming Soon"}</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={() => toggleNavs(1)}
                        >
                          <span className="d-none d-md-block">Total</span>
                          <span className="d-md-none">T</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={() => toggleNavs(2)}
                        >
                          <span className="d-none d-md-block">Spread</span>
                          <span className="d-md-none">S</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart" onTouchEnd={() => handleTouch(false)} onTouchStart={() => handleTouch(true)}>
                  {activeNav === 1 && <Line ref={totalsChart} className="line-chart" data={totalData} options={options} />}
                  {activeNav === 2 && <Line ref={spreadsChart} className="line-chart" data={spreadData} options={options} />}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
