import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { redirectUserToCheckout } from "../stripe/config.js";

export function IsLoggedIn({ children }) {
  const { user, isLoggedIn, isAccountActive } = useContext(UserContext);

  if (isLoggedIn === true) {
    if (isAccountActive === true) {
      //User is signed in and has paid their monthly fee
      return <Navigate to="/dashboard/index" />;
    } else {
      //User is signed in but has not paid their monthly fee
      //Force them to pay to continue using the app
      redirectUserToCheckout(user?.email);
    }
  } else {
    return children;
  }
}
