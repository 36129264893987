import { useEffect, useState } from "react";
import { firebaseAuth, firebaseDb } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";

export const useGetUser = () => {
  const [state, setState] = useState({
    user: null,
    isLoggedIn: false,
    isAccountActive: false,
  });

  useEffect(() => {
    const unsub = onAuthStateChanged(firebaseAuth, (firebaseUser) => {
      if (firebaseUser) {
        getDoc(doc(firebaseDb, "users", firebaseUser.uid))
          .then((user) => {
            const userData = user.data();
            setState({
              user: { id: firebaseUser.uid, ...userData },
              isLoggedIn: true,
              isAccountActive: userData.accountActive,
            });
          })
          .catch((error) => {
            console.error("Failed to get user document:", error);
          });
      } else {
        setState({ user: null, isLoggedIn: false, isAccountActive: false });
      }
    });

    return () => unsub();
  }, []);

  return state;
};
