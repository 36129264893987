import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { firebaseAnalytics } from "../../firebase/config.js";
import { logEvent } from "firebase/analytics";

import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import UserNavPopup from "../../views/examples/UserNavPopup.js";

const Sidebar = ({ routes, logo }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  const toggleCollapse = () => setCollapseOpen((prevState) => !prevState);

  const createLinks = (routes) =>
    routes.map((prop, key) =>
      prop.layout === "/dashboard" && prop.name !== "Account" ? (
        <NavItem key={key}>
          <NavLink
            to={prop.path}
            tag={NavLinkRRD}
            onClick={() => {
              setCollapseOpen(false);
              logEvent(firebaseAnalytics, "select_content", {
                content_type: "link",
                item_id: prop.path,
              });
            }}
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      ) : null
    );

  const navbarBrandProps = logo?.innerLink ? { to: logo.innerLink, tag: Link } : { href: logo.outterLink, target: "_blank" };

  return (
    <Navbar className="navbar-vertical fixed-left navbar-light bg-white" expand="md" id="sidenav-main">
      <Container fluid>
        <button className="navbar-toggler" type="button" onClick={toggleCollapse} aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        {logo && (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
          </NavbarBrand>
        )}
        <Nav className="align-items-center d-md-none">
          <UserNavPopup />
        </Nav>
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo && (
                <Col className="collapse-brand" xs="6">
                  <Link to={logo.innerLink ?? "/"}>
                    <img alt={logo.imgAlt} src={logo.imgSrc} />
                  </Link>
                </Col>
              )}
              <Col className="collapse-close" xs="6">
                <button className="navbar-toggler" type="button" onClick={toggleCollapse} aria-label="Close navigation">
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
