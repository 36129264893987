import { Container, Row, Col } from "reactstrap";
import profileCoverImage from "../../assets/img/theme/profile-cover.jpg";

const headerStyle = {
  minHeight: "600px",
  backgroundImage: `url(${profileCoverImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center top",
};

const UserHeader = ({ firstName }) => {
  return (
    <div className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center" style={headerStyle}>
      <span className="mask bg-gradient-default opacity-8" />
      <Container className="d-flex align-items-center" fluid>
        <Row>
          <Col lg="7" md="10">
            <h1 className="display-2 text-white">Hello {firstName}</h1>
            <p className="text-white mt-0 mb-5">This is your profile page. Please verify all your information looks correct.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserHeader;
