const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || "America/Chicago";

const dateOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  timeZone: timezone,
};

const timeOptions = {
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
  timeZone: timezone,
};

const formatDate = (dateString) => new Intl.DateTimeFormat("default", dateOptions).format(new Date(dateString));
const formatTime = (dateString) => new Intl.DateTimeFormat("default", timeOptions).format(new Date(dateString));

const getStatusBadgeColor = (status) => {
  switch (status) {
    case "Ongoing":
      return "bg-success";
    case "Finished":
      return "bg-danger";
    default:
      return "bg-warning";
  }
};

const getCellStyle = (liveTotal, threshold, isUnder) => {
  if (isUnder) {
    return liveTotal > threshold ? "text-success" : "";
  } else {
    return liveTotal < threshold ? "text-danger" : "";
  }
};

const getGameTotal = (game, bookmaker) => {
  let selectedBookmaker = game.bookmakers.find((b) => b.key === bookmaker);
  let totalsMarket = selectedBookmaker.markets.find((m) => m.key === "totals");
  return totalsMarket.outcomes[0].point;
};

const getLineValue = (sport, total, isUnder) => {
  let lineValue = total;
  let adjustment;
  switch (sport) {
    case "americanfootball_nfl":
      adjustment = total < 45 ? 13.5 : 14.5;
      break;
    case "basketball_nba":
      adjustment = total < 200 ? 14 : 15;
      break;
    case "americanfootball_ncaaf":
      adjustment = total < 45 ? 17.5 : total < 55 ? 18.5 : total < 65 ? 20 : 22.5;
      break;
    case "baseball_mlb":
      adjustment = total < 9.5 ? 4 : 4.5;
      break;
    default:
      console.log("Got to default in the Switch Statement. Something is wrong...");
      return lineValue;
  }
  return isUnder ? lineValue + adjustment : lineValue - adjustment;
};

export { formatDate, formatTime, getStatusBadgeColor, getCellStyle, getGameTotal, getLineValue };
