import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupText, InputGroup, Row, Col } from "reactstrap";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { firebaseAuth, firebaseAnalytics } from "../../firebase/config.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { setUserId, logEvent } from "firebase/analytics";
import AlertPopup from "./AlertPopup.js";

const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [password, setPassword] = useState("");
  const [alertInfo, setAlertInfo] = useState({ visible: false, type: "danger", message: "" });
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setAlertInfo({ visible: true, type: "danger", message: "Please enter a valid email" });
      return;
    }
    try {
      const cred = await signInWithEmailAndPassword(firebaseAuth, email, password);
      setUserId(firebaseAnalytics, cred.user.uid);
      logEvent(firebaseAnalytics, "login", { method: "email" });
      navigate("/dashboard/index");
    } catch (err) {
      setAlertInfo({
        visible: true,
        type: "danger",
        message: `${err.code === "auth/user-not-found" ? "No user with such email" : "Invalid email or password"}`,
      });
    }
    if (rememberMe) {
      localStorage.setItem("email", email);
    } else {
      localStorage.removeItem("email");
    }
  };

  // useEffect(() => {
  //   const rememberedEmail = localStorage.getItem("email");
  //   if (rememberedEmail) {
  //     setEmail(rememberedEmail);
  //     setRememberMe(true);
  //   }
  // }, []);

  useEffect(() => {
    setRememberMe(!!localStorage.getItem("email"));
  }, []);

  return (
    <>
      <AlertPopup
        showAlert={alertInfo.visible}
        alertType={alertInfo.type}
        onAlertClick={() => setAlertInfo({ ...alertInfo, visible: false })}
        message={alertInfo.message}
      />
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in with</small>
            </div>
            <Form role="form" className="login-form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                  <Input value={email} placeholder="Email" type="email" autoComplete="new-email" name="email" onChange={(e) => setEmail(e.target.value)} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                  <Input
                    placeholder="Password"
                    value={password}
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id="customCheckLogin"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label className="custom-control-label" htmlFor="customCheckLogin">
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col className="text-left" xs="6">
            <Link className="text-light" to="/auth/forgot-password" tag={Link}>
              <small>Forgot password?</small>
            </Link>
          </Col>

          <Col className="text-right" xs="6">
            <Link className="text-light" to="/auth/register" tag={Link}>
              <small>Create new account</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
