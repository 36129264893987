import WelcomePage from "./views/WelcomePage.js";
import Profile from "./views/examples/Profile.js";
import Register from "./views/examples/Register.js";
import Login from "./views/examples/Login.js";
import Games from "./views/examples/Games.js";
import ForgotPassword from "./views/examples/ForgotPassword.js";

const createGameRoute = (league, name, icon, headerIconClass) => {
  return {
    path: league,
    name: name,
    icon: icon,
    component: <Games league={league} shortName={name} headerIconClass={headerIconClass} />,
    layout: "/dashboard",
  };
};

var routes = [
  {
    path: "index",
    name: "Welcome",
    icon: "ni ni-tv-2 text-primary",
    component: <WelcomePage />,
    layout: "/dashboard",
  },
  createGameRoute("americanfootball_nfl", "NFL", "fa-solid fa-football text-brown", "fa-solid fa-football"),
  createGameRoute("basketball_nba", "NBA", "fa-solid fa-basketball text-orange", "fa-solid fa-basketball"),
  createGameRoute("americanfootball_ncaaf", "NCAAF", "fa-solid fa-football text-brown", "fa-solid fa-football"),
  createGameRoute("baseball_mlb", "MLB", "fa-solid fa-baseball-bat-ball text-blue", "fa-solid fa-baseball-bat-ball"),
  {
    path: "user-profile",
    name: "Account",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/dashboard",
  },

  {
    path: "login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: <Register />,
    layout: "/auth",
  },
  {
    path: "forgot-password",
    name: "Forgot Password",
    icon: "ni ni-circle-08 text-pink",
    component: <ForgotPassword />,
    layout: "/auth",
  },
];
export default routes;
