var mode = "light";

var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#6340fb",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

const labels = [
  "0",
  "5",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
  "60",
  "65",
  "70",
  "75",
  "80",
  "85",
  "90",
  "95",
  "100",
  "105",
  "110",
  "115",
  "120",
  "125",
  "130",
  "135",
  "140",
  "145",
  "150",
  "155",
  "160",
  "165",
  "170",
  "175",
  "180",
  "185",
  "190",
  "195",
  "200",
  "205",
  "210",
];

const data = {
  labels: labels,
  datasets: [
    {
      label: "Performance",
      data: [0, 20, 10, 30, 15, 40, 20, 60, 60, 20, 25, 23, 33, 29, 50],
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  color: mode === "dark" ? colors.gray[700] : colors.gray[600],
  borderColor: mode === "dark" ? colors.gray[700] : colors.gray[600],
  backgroundColor: mode === "dark" ? colors.gray[700] : colors.gray[600],
  layout: {
    padding: 0,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      mode: "index",
      intersect: false,
    },
  },
  elements: {
    point: {
      radius: 0,
      backgroundColor: colors.theme["primary"],
    },
    line: {
      tension: 0.4,
      borderWidth: 4,
      borderColor: colors.theme["primary"],
      backgroundColor: colors.transparent,
      borderCapStyle: "rounded",
    },
    bar: {
      backgroundColor: colors.theme["warning"],
    },
    arc: {
      backgroundColor: colors.theme["primary"],
      borderColor: mode === "dark" ? colors.gray[800] : colors.white,
      borderWidth: 4,
    },
  },

  scales: {
    y: {
      grid: {
        display: false,
        drawBorder: false,
        borderDash: [2],
        borderDashOffset: 2.0,
        lineWidth: 0,
      },
      ticks: {
        beginAtZero: false,
        padding: 10,
        color: mode === "dark" ? colors.gray[900] : colors.gray[600],
        font: {
          family: "Open Sans",
          size: 13,
        },
        callback: function (value) {
          return value;
        },
      },
    },
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        beginAtZero: true,
        padding: 20,
        color: mode === "dark" ? colors.gray[900] : colors.gray[600],
        font: {
          family: "Open Sans",
          size: 13,
        },
      },
    },
  },
};

export { data, options };
