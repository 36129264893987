import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";
import UserHeader from "./../../components/Headers/UserHeader.js";
import { useGetUser } from "../../hooks/useGetUser";
import { doc, updateDoc } from "firebase/firestore";
import { firebaseDb } from "../../firebase/config";
import { useEffect, useState } from "react";
import { redirectUserToAccountManagement } from "../../stripe/config.js";
import AlertPopup from "./AlertPopup.js";

const Profile = () => {
  const { user } = useGetUser();
  const [visible, setVisible] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [stripeId, setStripeId] = useState("");
  const [alertType, setAlertType] = useState("danger");
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
    setEmail(user?.email);
    setPin(user?.pin);
    setStripeId(user?.stripeId);
  }, [user?.email, user?.firstName, user?.lastName, user?.pin, user?.stripeId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    updateDoc(doc(firebaseDb, "users", user.id), {
      firstName: firstName,
      lastName: lastName,
      email: email,
      pin: pin,
    }).then(() => {
      setAlertType("success");
      setVisible(true);
      setAlertMessage("Your account has been updated successfully!");
    });
  };

  return (
    <>
      <AlertPopup showAlert={visible} alertType={alertType} onAlertClick={(e) => setVisible(false)} message={alertMessage} />
      <UserHeader firstName={firstName} />

      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">User information</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form
                  role="form"
                  className="user-info-form"
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-email">
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={email || ""}
                            name="input-email"
                            placeholder="user@example.com"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-discord-pin">
                            Security pin (For Discord verification)
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={pin || ""}
                            placeholder="Discord pin"
                            pattern="^[0-9]{4,}$"
                            type="text"
                            name="input-discord-pin"
                            onChange={(e) => setPin(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-first-name">
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={firstName || ""}
                            placeholder="First name"
                            type="text"
                            name="input-first-name"
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-last-name">
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={lastName || ""}
                            placeholder="Last name"
                            type="text"
                            name="input-last-name"
                            onChange={(e) => setLastName(e.target.value)}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="text-center">
                      <Button className="mt-4" color="primary" type="submit">
                        Update
                      </Button>
                      {stripeId && (
                        <Button
                          className="mt-4"
                          color="info"
                          onClick={(e) => {
                            redirectUserToAccountManagement(stripeId);
                          }}
                        >
                          Manage Subscription
                        </Button>
                      )}
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
