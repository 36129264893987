import "chart.js/auto";

import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";

import Header from "../components/Headers/Header.js";
import { useLocation } from "react-router-dom";

const WelcomePage = () => {
  const { state } = useLocation();

  return (
    <>
      <Header showHeaders={false} showStats={true} total={state?.total} percentage={""} showSpreadHeader={false} />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0">
            <Card className="bg-gradient-white shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h1 className="text-uppercase text-dark ls-1 mb-1">Welcome to BetMagic!</h1>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="text-uppercase text-dark ls-1 mb-1">Getting Started</h2>
                    <p className="text-dark ls-1 mb-1">
                      Choose your favorite sport in the menu and start betting! You can follow along with the game and the volatility with each individual game
                      to see when the right time to bet approaches.
                    </p>
                    <p className="text-dark ls-1 mb-1">
                      Remember, live betting lines move fast. We recommend you stay logged in to your sportsbook and ready to make the Bet. Stay up to date by
                      receiving live text messages as well.
                    </p>
                  </div>
                </Row>
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="text-uppercase text-dark ls-1 mb-1">Betting Best Practices</h2>
                    <h4 className="text-uppercase text-dark ls-1 mb-1">
                      Using BetMagic is a cheat code for Live Sports Betting. However, we suggest some rules in doing so:
                    </h4>
                    <ol>
                      <li className="text-dark ls-1 mb-1">
                        Never Bet on Emotion. Do not make bigger or smaller bets based off 'What you think will happen'. Our system and algorithm works on the
                        odds, not the emotion.
                      </li>
                      <li className="text-dark ls-1 mb-1">
                        Bet in same units. Piggybacking on the previous point, bet the same amount each game. We recommend Betting 1 unit each game. For
                        example: if your average bet is 25$, bet $25 each game.
                      </li>
                      <li className="text-dark ls-1 mb-1">
                        Bet Responsibly. Gambling should be entertainment, not for income. Do not bet more than you can afford to lose.
                      </li>
                    </ol>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WelcomePage;
