import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupText, InputGroup, Row, Col } from "reactstrap";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { firebaseAuth } from "../../firebase/config.js";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { firebaseDb, firebaseAnalytics } from "../../firebase/config";
import { setUserId, logEvent } from "firebase/analytics";
import { stripePromise, redirectUserToCheckout } from "../../stripe/config.js";
import AlertPopup from "./AlertPopup.js";

const Register = () => {
  const stripeRef = useRef();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    pin: "",
    password: "",
    agreedToLegal: false,
    promoCode: "",
  });

  const [passwordState, setPasswordState] = useState({ status: "invalid", message: "Password does not meet criteria" });
  const [alertInfo, setAlertInfo] = useState({ visible: false, type: "danger", message: "" });

  useEffect(() => {
    stripePromise.then((res) => {
      stripeRef.current = res;
    });
  }, []);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (name === "password") {
      validatePassword(value);
    }
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=\S+$).{8,}$/;
    const isValid = regex.test(password);
    setPasswordState({
      status: isValid ? "valid" : "invalid",
      message: isValid ? "Password is valid" : "Password does not meet criteria",
    });
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.promoCode) return; // Honey pot for bots

    if (validatePassword(formData.password) && formData.agreedToLegal) {
      try {
        const cred = await createUserWithEmailAndPassword(firebaseAuth, formData.email, formData.password);
        await setDoc(doc(firebaseDb, "users", cred.user.uid), {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          pin: formData.pin,
          stripeId: "",
          stripePaymentStatus: "pending",
          accountActive: false,
          createdAt: serverTimestamp(),
        });
        setUserId(firebaseAnalytics, cred.user.uid);
        logEvent(firebaseAnalytics, "sign_up", { method: "email" });
        redirectUserToCheckout(formData.email);
      } catch (err) {
        setAlertInfo({ visible: true, type: "danger", message: `Could not create user: ${err.message}` });
      }
    }
  };

  return (
    <>
      <AlertPopup
        showAlert={alertInfo.visible}
        alertType={alertInfo.type}
        onAlertClick={() => setAlertInfo({ ...alertInfo, visible: false })}
        message={alertInfo.message}
      />
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <img src={require("../../assets/img/brand/blacklogo.png")} width={150} alt="betmagic logo" />
              <br />
              <small>1.Sign up for Bet Magic Below</small>
              <br />
              <small>2. Complete Checkout Page</small>
              <br />
              <small>3. Start Betting with an Edge!</small>
            </div>
            <Form role="form" className="register-form" onSubmit={(e) => handleSubmit(e)}>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupText>
                    <i className="ni ni-single-02" />
                  </InputGroupText>
                  <Input id="firstName" placeholder="First Name" type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
                </InputGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupText>
                    <i className="ni ni-single-02" />
                  </InputGroupText>
                  <Input id="lastName" placeholder="Last Name" type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
                </InputGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                  <Input
                    id="email"
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </InputGroup>

                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupText>
                    <i className="fas fa-hashtag" />
                  </InputGroupText>
                  <Input
                    id="pin"
                    placeholder="Set 4 Digit Numerical Discord PIN"
                    type="text"
                    name="pin"
                    value={formData.pin}
                    pattern="^[0-9]{4,}$"
                    onChange={handleChange}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                  <Input
                    id="password"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=\S+$).{8,}$"
                    required
                    value={formData.password}
                    onChange={handleChange}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-muted font-italic">
                <small>
                  Password must contain:
                  <br />
                  <ul>
                    <li>at least 8 Characters</li>
                    <li>at least 1 lowercase AND uppercase character </li>
                    <li>at least 1 special character</li>
                    <li>at least 1 number</li>
                  </ul>
                  <span className={`is-password-valid ${passwordState.status === "invalid" ? "text-danger" : "text-success"} font-weight-700`}>
                    {passwordState.message}
                  </span>
                </small>
              </div>
              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="agreedToLegal"
                      name="agreedToLegal"
                      type="checkbox"
                      checked={formData.agreedToLegal}
                      onChange={handleChange}
                      required
                    />
                    <label className="custom-control-label" htmlFor="agreedToLegal">
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="https://betmagic.app/privacy-policy/" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                        &nbsp;and{" "}
                        <a href="https://betmagic.app/privacy-policy/" onClick={(e) => e.preventDefault()}>
                          Terms of Use
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>{" "}
              <Input placeholder="Promo Code" name="promoCode" value={formData.promoCode} onChange={handleChange} style={{ display: "none" }} />
              <div className="text-center">
                <Button className="mt-4" color="primary" type="submit">
                  Proceed to Checkout
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link className="text-light " to="/auth/login" tag={Link}>
              <small>Back to login</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Register;
