import { Card, CardHeader, CardFooter, Pagination, PaginationItem, PaginationLink, Container, Row } from "reactstrap";
import Header from "../../components/Headers/Header.js";

import { useFetchGames } from "../../hooks/useFetchGames.js";
import { isAfter, isToday, parseISO } from "date-fns";
import GamesTable from "./GameTable.js";
import { useState } from "react";

const Games = (props) => {
  const { games, isPending } = useFetchGames();
  const [currentPage, setCurrentPage] = useState(1);
  const gamesPerPage = 10;

  const handlePaginationLinkClick = (newPage) => (e) => {
    e.preventDefault();
    setCurrentPage(newPage);
  };

  const getTodaysGames = () => {
    const gamesToday = games
      .filter((game) => {
        return isToday(parseISO(game.commence_time)) && game.sport_key === props.league;
      })
      .sort((a, b) => new Date(a.commence_time) - new Date(b.commence_time));
    return gamesToday.length > 0 ? gamesToday : [];
  };

  const getUpcomingGames = () => {
    const upcomingGames = games
      .filter((game) => {
        return isAfter(parseISO(game.commence_time), new Date()) && !isToday(parseISO(game.commence_time)) && game.sport_key === props.league;
      })
      .sort((a, b) => new Date(a.commence_time) - new Date(b.commence_time));
    const startIndex = (currentPage - 1) * gamesPerPage;
    const selectedGames = upcomingGames.slice(startIndex, startIndex + gamesPerPage);

    return selectedGames.length > 0 ? selectedGames : [];
  };

  return (
    <>
      <Header
        showHeaders={true}
        league={props.shortName}
        currentGames={getTodaysGames().length}
        headerIconClass={props.headerIconClass}
        showStats={false}
        showSpreadHeader={false}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">{isPending ? "Loading Today's Games..." : "Today's Games"}</h3>
              </CardHeader>
              <GamesTable
                games={getTodaysGames()}
                tableClassName={"table-flush"}
                theadClassName={"thead-light"}
                noGameText={"No games today"}
                isCurrentGames={true}
                sportsIconClass={props.headerIconClass}
              />
            </Card>
          </div>
        </Row>

        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <h3 className="text-white mb-0">{isPending ? "Loading Upcoming Games..." : "Upcoming Games"}</h3>
              </CardHeader>
              <GamesTable
                games={getUpcomingGames()}
                tableClassName={"table-dark table-flush"}
                theadClassName={"thead-dark"}
                noGameText={"Currently no upcoming games"}
                isCurrentGames={false}
                sportsIconClass={props.headerIconClass}
              />
              {getUpcomingGames().length > 0 ? (
                <CardFooter className="py-4 bg-default shadow">
                  <nav aria-label="...">
                    <Pagination className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
                      <PaginationItem className={currentPage === 1 ? "disabled" : ""}>
                        <PaginationLink href="#pablo" onClick={handlePaginationLinkClick(currentPage - 1)} tabIndex="-1">
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink href="#pablo" onClick={(e) => e.preventDefault()}>
                          {currentPage}
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className={getUpcomingGames().length < gamesPerPage ? "disabled" : ""}>
                        <PaginationLink href="#pablo" onClick={handlePaginationLinkClick(currentPage + 1)}>
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              ) : (
                ""
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Games;
