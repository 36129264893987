import { useRef, useEffect } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { Container } from "reactstrap";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import DashboardFooter from "../components/Footers/DashboardFooter";
import Sidebar from "../components/Sidebar/Sidebar";

import routes from "../routes";
import Index from "../views/Index";

const Dashboard = () => {
  const mainContent = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (mainContent.current) mainContent.current.scrollTop = 0;
  }, [location]);

  const dashboardRoutes = routes
    .filter((route) => route.layout === "/dashboard")
    .map((route, key) => <Route key={key} path={route.path} element={route.component} />);

  const gameRoutes = ["americanfootball_nfl", "basketball_nba", "americanfootball_ncaaf", "baseball_mlb"].map((path, key) => (
    <Route key={key} path={`/${path}/:gameId`} element={<Index />} />
  ));

  return (
    <>
      <Sidebar
        routes={routes}
        logo={{
          innerLink: "/dashboard/index",
          imgSrc: require("../assets/img/brand/blacklogo.png"),
          imgAlt: "Bet Magic Logo",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <DashboardNavbar />
        <Routes>
          {dashboardRoutes}
          {gameRoutes}
          <Route path="*" element={<Navigate to="/dashboard/index" replace />} />
        </Routes>
        <Container fluid>
          <DashboardFooter />
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
