import { useCallback, useEffect, useState } from "react";
import { query, onSnapshot, orderBy, where, doc, getDoc } from "firebase/firestore";
import { spreadCollection, firebaseDb } from "../firebase/config.js";
import differenceInMinutes from "date-fns/differenceInMinutes";
import parseISO from "date-fns/parseISO";

export const useFetchGameSpreads = (gameId) => {
  const [spreadData, setSpreadData] = useState({
    labels: ["00:00"],
    datasets: [
      {
        label: "teamOne",
        data: [-1],
        borderColor: "#40fb63",
        backgroundColor: "#40fb63",
      },
      {
        label: "teamTwo",
        data: [1],
      },
    ],
  });

  const [spread, setSpread] = useState([
    { name: "N/A", point: 0 },
    { name: "N/A", point: 0 },
  ]);

  const getSpread = useCallback(async (gameDocRef) => {
    const game = await getDoc(gameDocRef);
    let selectedBookmaker = game.data().bookmakers.find((b) => b.key === "draftkings");
    let spreadsMarket = selectedBookmaker.markets.find((m) => m.key === "spreads");
    const spread = spreadsMarket.outcomes;
    const startTime = game.data().commence_time;
    setSpread(spread);
    return startTime;
  }, []);

  useEffect(() => {
    if (!gameId) return;

    const fetchGameSpreads = async () => {
      const gameDocRef = doc(firebaseDb, "games", gameId);
      const startTime = await getSpread(gameDocRef);
      const q = query(spreadCollection, where("gameId", "==", gameDocRef), orderBy("createdAt", "asc"));

      const unsub = onSnapshot(q, (snapshot) => {
        const liveTeamOneSpreads = snapshot.docs.map((doc) => doc.data().outcomes[0].point);
        const liveTeamTwoSpreads = snapshot.docs.map((doc) => doc.data().outcomes[1].point);
        const teamOne = snapshot.docs[0]?.data().outcomes[0].name || "teamOne";
        const teamTwo = snapshot.docs[0]?.data().outcomes[1].name || "teamTwo";
        const timestampLabels = snapshot.docs.map((doc) => {
          const time = Math.max(0, differenceInMinutes(parseISO(doc.data().createdAt), parseISO(startTime)));
          const hour = Math.floor(time / 60);
          const minute = time % 60;
          return `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
        });

        setSpreadData({
          labels: timestampLabels,
          datasets: [
            {
              label: teamOne,
              data: liveTeamOneSpreads,
              borderColor: "#40fb63",
              backgroundColor: "#40fb63",
            },
            {
              label: teamTwo,
              data: liveTeamTwoSpreads,
            },
          ],
        });
      });

      return () => unsub();
    };

    fetchGameSpreads();
  }, [gameId, getSpread]);

  return { spread, spreadData };
};
